import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageSettings } from "../../config/page-settings";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  EditUpdateTicketAsync,
  GetTicketByZZIDUnsecuredAction,
  addSurveyAction,
} from "../../redux/actions/maintainance";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import logo from "./../../assets/img/logo/AniaLogo-full.svg";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import heic2any from "heic2any";
import * as _ from "lodash";
import { Notify } from "../../helpers/notification";
import { getPeopleByZZID } from "../../redux/actions/people";

const NotSecureMaintainance = () => {
  const context = useContext(PageSettings);
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { ticket_zzid, people_zzid, action } = useParams();
  const dispatch = useDispatch();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [imgdata, setImgData] = useState([]);
  const [isShowImagePicketModal, setIsShowImagePicketModal] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [note, setNote] = useState("");

  const [modalError, setModalError] = useState("");
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [submitSurveyLoading, setSubmitSurveyLoading] = useState(false);
  const [surveyModalHeading, setSurveyModalHeading] = useState("");
  const [answered, setAnswered] = useState(false);
  const [survey, setSurvey] = useState({
    formData: {
      technician: {
        sectionTitle: "The technician...",
        questions: [
          {
            question: t("modal.maintenance.survey.q1"),
            order: 1,
            answer: "",
            name: "understand",
          },
          {
            question: t("modal.maintenance.survey.q2"),
            order: 2,
            answer: "",
            name: "knowledgable",
          },
          {
            question: t("modal.maintenance.survey.q3"),
            order: 3,
            answer: "",
            name: "professional",
          },
          {
            question: t("modal.maintenance.survey.q4"),
            order: 4,
            answer: "",
            name: "satisfied",
          },
        ],
      },
      florio: {
        sectionTitle: "florio",
        questions: [
          {
            question: t("modal.maintenance.survey.q5"),
            order: 1,
            answer: "",
            name: "responseTime",
          },
        ],
      },
    },
  });

  const [ticketDetails, setTicketDetails] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") === "es" ? "Spanish" : "English"
  );
  const { isAuth, user } = useSelector(({ USER_REDUCER }) => ({
    isAuth: USER_REDUCER.isAuth,
    user: USER_REDUCER.userData,
  }));
  const [people, setPeople] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        // Hide sidebar and header
        context.handleSetPageSidebar(false);
        context.handleSetPageHeader(false);

        // Fetch ticket data
        dispatch(
          GetTicketByZZIDUnsecuredAction(ticket_zzid, async (event) => {
            if (!event) return;

            const { fieldData, portalData, _id } = event;

            // Verify action handling
            if (people_zzid && action === "verify") {
              handleActionVerify(fieldData, portalData, _id, event);
            }

            // reopen action handling
            if (people_zzid && action === "reopen") {
              handleActionReOpen(fieldData, portalData, _id, event);
            }

            // Default: set ticket details
            setTicketDetails(event);
          })
        );
      } catch (error) {
        console.error("Error initializing ticket details:", error);
        setModalError("An unexpected error occurred. Please try again.");
      }
    };

    initialize();
    console.log(ticketDetails, "ticketDetails");
  }, []);

  const handleActionVerify = async (fieldData, portalData, _id, event) => {
    if (fieldData?.Status !== "Closed") {
      setModalError(
        "The Status of this Maintenance Ticket is not yet `Closed` and therefore is not yet eligible to be Verified."
      );

      setTicketDetails(event);
      return;
    }

    // Locate the current user in the lease details
    const leaseDetails = portalData?.leaseDetails;
    const _people = leaseDetails?.portalData?.Lease_LEPE_LeasePeople_all?.find(
      (x) => x["Lease_PEPL_LeasePeople_all::zzID"] === people_zzid
    );

    if (!_people?.["Lease_PEPL_LeasePeople_all::zzID"]) {
      setModalError(
        "You are not allowed to take this action. If you feel this is an error, please contact the office."
      );
      setTicketDetails(event);
      return;
    }

    setPeople(_people);
    // Prepare data for verification
    const formData = new FormData();
    formData.append("_id", _id);
    formData.append("status", "Verified by Tenant");
    formData.append(
      "VerifiedBy",
      _people["Lease_PEPL_LeasePeople_all::Email_01"]
    );
    formData.append("VerifiedDate", moment().format("DD/MM/YYYY"));
    formData.append("VerifiedTS", moment().format("DD/MM/YYYY HH:mm:ss"));
    formData.append(
      "zzModifiedByWeb",
      _people["Lease_PEPL_LeasePeople_all::Email_01"]
    );

    // Update ticket details
    const response = await EditUpdateTicketAsync(formData);
    setTicketDetails(response);
    setSurveyModalHeading("Please share you feedback");
    setShowSurveyModal(true);
    return;
  };

  const handleActionReOpen = async (fieldData, portalData, _id, event) => {
    if (fieldData?.Status !== "Closed" && fieldData?.Status !== "Verified") {
      setModalError(
        "The current status of this maintenance ticket does not allow it to be reopened at this time."
      );

      setTicketDetails(event);
      return;
    }

    // Locate the current user in the lease details
    const leaseDetails = portalData?.leaseDetails;
    const _people = leaseDetails?.portalData?.Lease_LEPE_LeasePeople_all?.find(
      (x) => x["Lease_PEPL_LeasePeople_all::zzID"] === people_zzid
    );

    if (!_people?.["Lease_PEPL_LeasePeople_all::zzID"]) {
      setModalError(
        "You are not allowed to take this action. If you feel this is an error, please contact the office."
      );
      setTicketDetails(event);
      return;
    }

    setPeople(_people);
    // Prepare data for verification
    const formData = new FormData();
    formData.append("_id", _id);
    formData.append("status", "Re-Opened by Tenant");
    formData.append(
      "VerifiedBy",
      _people["Lease_PEPL_LeasePeople_all::Email_01"]
    );
    formData.append("VerifiedDate", "");
    formData.append("VerifiedTS", "");
    formData.append("ScheduledTime_End", "");
    formData.append("ScheduledTime_Start", "");
    formData.append(
      "zzModifiedByWeb",
      _people["Lease_PEPL_LeasePeople_all::Email_01"]
    );

    // Update ticket details
    const response = await EditUpdateTicketAsync(formData);
    setTicketDetails(response);
    setShowSurveyModal(true);
    setSurveyModalHeading("Please tell us more");
    return;
  };

  const gotoLogin = () => {
    history.push("/dashboard", { redirect_url: `/maintainance-ticket-details/${ticket_zzid}` });
  };

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const statusTranslation = (text) => {
    return text === "New"
      ? t("maintenance-status.new")
      : text === "Verified"
      ? t("maintenance-status.verified")
      : text === "Verified by Tenant"
      ? t("maintenance-status.verified-tenant")
      : text === "Closed"
      ? t("maintenance-status.closed")
      : text === "Scheduled"
      ? t("maintenance-status.scheduled")
      : text === "Re-Opened by Tenant"
      ? t("maintenance-status.re-opened")
      : text === "Scheduled - Confirmed"
      ? t("maintenance-status.scheduled-confirmed")
      : text === "Canceled"
      ? t("maintenance-status.canceled")
      : text;
  };

  const staticQuestions = {
    understand: "The technician understood my request",
    knowledgable: "The technician was knowledgeable",
    professional: "The technician acted in a professional manor",
    satisfied: "Overall, I am very satisfied with the technician",
    responseTime: "Ania Management responded in a timely manner.",
  };

  const categoryTranslation = (text) => {
    return text === "Cleaning"
      ? t("maintenance-category.cleaning")
      : text === "Plumbing"
      ? t("maintenance-category.plumbing")
      : text === "Extermination"
      ? t("maintenance-category.extermination")
      : text === "Electricity"
      ? t("maintenance-category.electricity")
      : text === "Scheduled"
      ? t("maintenance-category.scheduled")
      : text === "Carpentry"
      ? t("maintenance-category.carpentry")
      : text === "Landscaping"
      ? t("maintenance-category.landscaping")
      : text;
  };

  const subCategoryTranslation = (text) => {
    return text === "Leaks"
      ? t("maintenance-subCategory.leaks")
      : text === "Air Conditioning"
      ? t("maintenance-subCategory.ac")
      : text === "Heat"
      ? t("maintenance-subCategory.heat")
      : text === "Front Yard"
      ? t("maintenance-subCategory.frontYard")
      : text === "Back Yard"
      ? t("maintenance-subCategory.backYard")
      : text === "Side Yard"
      ? t("maintenance-subCategory.sideYard")
      : text === "Rats/Mice"
      ? t("maintenance-subCategory.rat")
      : text === "Cockroaches"
      ? t("maintenance-subCategory.cockroaches")
      : text === "Ants"
      ? t("maintenance-subCategory.ants")
      : text === "Doors"
      ? t("maintenance-subCategory.doors")
      : text === "Walls"
      ? t("maintenance-subCategory.walls")
      : text === "Ceiling"
      ? t("maintenance-subCategory.ceiling")
      : text === "Lights"
      ? t("maintenance-subCategory.lights")
      : text === "Sockets"
      ? t("maintenance-subCategory.sockets")
      : text === "Entryway"
      ? t("maintenance-subCategory.entryway")
      : text === "Hallway"
      ? t("maintenance-subCategory.hallway")
      : text === "Trash Area"
      ? t("maintenance-subCategory.trash")
      : text === "Large Debris"
      ? t("maintenance-subCategory.debris")
      : text;
  };

  const whereListTranslation = (text) => {
    return text === "Bedroom"
      ? t("maintenance-where.bedroom")
      : text === "Bathroom"
      ? t("maintenance-where.bathroom")
      : text === "Kitchen"
      ? t("maintenance-where.kitchen")
      : text === "Living Room"
      ? t("maintenance-where.living-room")
      : text === "Hallway"
      ? t("maintenance-where.hallway")
      : text === "Whole Unit"
      ? t("maintenance-where.whole-unit")
      : text;
  };

  const handleImage = async (imageList) => {
    let imgArr = [];
    let maxlen = imageList.length;
    for (let i = 0; i < maxlen; i++) {
      let _imgData = imageList[i];
      try {
        if (_imgData.name.split(".")[1].toLowerCase() === "heic") {
          const result = await heic2any({
            blob: _imgData,
            toType: "image/jpeg",
            quality: 1,
          });
          const convertedFile = new File([result], `${_imgData.name}.jpeg`, {
            type: "image/jpeg",
          });
          const url = URL.createObjectURL(result);
          imgArr.push({ url, name: _imgData.name, file: convertedFile });
        } else {
          if (_imgData && _imgData.type.split("/")[0] === "image") {
            const url = URL.createObjectURL(_imgData);
            imgArr.push({ url, name: _imgData.name, file: _imgData });
          }
        }
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
      }
    }
    if (imgArr.length) {
      setImgData([...imgdata, ...imgArr]);
    }
  };

  const handleAddImages = async () => {
    try {
      if (!imgdata?.length) {
        return;
      }
      setImageLoading(true);
      const formData = new FormData();
      imgdata.forEach((x) => {
        formData.append("upload", x.file);
      });
      formData.append("_id", ticketDetails._id);
      formData.append("zzCreatedByWeb", user.Email_01);
      formData.append("zzModifiedByWeb", user.Email_01);
      const response = await EditUpdateTicketAsync(formData);
      console.log(response);
      if (response) {
        setTicketDetails(response);
        setIsShowImagePicketModal(false);
        setImgData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleAddNote = async () => {
    try {
      if (!note || !note?.length) {
        return;
      }
      setImageLoading(true);
      const formData = new FormData();
      formData.append("_id", ticketDetails._id);
      formData.append("note", note);
      formData.append("zzCreatedByWeb", user.Email_01);
      formData.append("zzModifiedByWeb", user.Email_01);
      const response = await EditUpdateTicketAsync(formData);
      if (response) {
        setTicketDetails(response);
        setIsShowImagePicketModal(false);
        setNote("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleAnswer = (question, index, value) => {
    !answered && setAnswered(true);
    let obj = _.cloneDeep(survey);
    let obj1 = {
      formData: {
        ...obj.formData,
        [question]: {
          ...obj.formData[question],
          questions: obj.formData[question].questions.map((item, i) =>
            i === index ? { ...item, answer: value } : item
          ),
        },
      },
    };
    let sorted = {
      formData: {
        technician: obj1.formData.technician,
        florio: obj1.formData.florio,
      },
    };
    setSurvey(sorted);
  };

  const handleSurveySubmit = async () => {
    if (
      ticketDetails?.fieldData?.Status?.toLowerCase() === "verified by tenant"
    ) {
      if (answered) {
        let payload = {
          zzId_MAIN: ticketDetails?.fieldData?.zzID,
          result: JSON.stringify({
            formData: {
              technician: {
                sectionTitle: "The technician...",
                questions: survey.formData["technician"].questions.map(
                  (que) => ({
                    ...que,
                    question: staticQuestions[que.name],
                  })
                ),
              },
              florio: {
                sectionTitle: "florio",
                questions: survey.formData["florio"].questions.map((que) => ({
                  ...que,
                  question: staticQuestions[que.name],
                })),
              },
            },
          }),
          zzModifiedByWeb:
            user?.Email_01 || people["Lease_PEPL_LeasePeople_all::Email_01"],
        };
        setSubmitSurveyLoading(true);
        dispatch(
          addSurveyAction(payload, () => {
            setAnswered(false);
            setSubmitSurveyLoading(false);
            setShowSurveyModal(false);
            setSurveyModalHeading("");
          })
        );
      } else {
        Notify("info", "Atleast select one answer");
      }
    } else {
      const formData = new FormData();
      formData.append("_id", ticketDetails._id);
      formData.append("note", note);
      formData.append(
        "zzCreatedByWeb",
        user.Email_01 || people["Lease_PEPL_LeasePeople_all::Email_01"]
      );

      setSubmitSurveyLoading(true);
      const response = await EditUpdateTicketAsync(formData);
      setTicketDetails(response);
      setAnswered(false);
      setSubmitSurveyLoading(false);
      setShowSurveyModal(false);
      setSurveyModalHeading("");
      if (
        ticketDetails?.fieldData?.Status?.toLowerCase() === "re-open by tenant"
      ) {
        setModalError(
          "Thank you for Letting us know. We have re-opened this ticket and will alert the Maintenance Staff"
        );
      }
    }
  };

  const renderImageDropzoneModal = () => {
    return (
      <Modal isOpen={isShowImagePicketModal}>
        <ModalHeader>Add Image</ModalHeader>
        <ModalBody>
          <Dropzone onDrop={(acceptedFiles) => handleImage(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  style={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <p>
                    Drop files <b>here</b> or <b>click</b> to upload (Max: 2
                    images).
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
            {imgdata.length > 0 ? (
              imgdata.map((item, i) => (
                <div key={i}>
                  <img
                    src={item.url}
                    alt={`Uploaded preview ${i + 1}`}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    {item.name}
                  </p>
                </div>
              ))
            ) : (
              <p>No images uploaded yet.</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#e4e7ea" }}>
          <button
            disabled={imageLoading}
            className="btn btn-secondary"
            onClick={() => {
              setImgData([]);
              setIsShowImagePicketModal(false);
            }}
          >
            Close
          </button>
          <button
            disabled={imageLoading}
            className="btn btn-primary"
            onClick={() => {
              handleAddImages();
            }}
          >
            {imageLoading ? (
              <Spinner color="white" size="sm" />
            ) : (
              "Add Image(s)"
            )}
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderErrorModal = () => {
    return (
      <Modal isOpen={modalError?.length > 0} centered>
        <ModalBody>
          <p>{modalError}</p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => setModalError("")}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderSurveyModal = () => {
    return (
      <Modal isOpen={showSurveyModal}>
        <ModalHeader> {surveyModalHeading} </ModalHeader>
        <ModalBody>
          <section>
            <div className="survey-container">
              {ticketDetails &&
              ticketDetails.fieldData?.Status?.toLowerCase() ==
                "verified by tenant" ? (
                survey &&
                survey.formData &&
                ["technician", "florio"].map((item) => (
                  <>
                    {survey.formData[item].questions.map((que, num) => (
                      <div key={num}>
                        <h5 className="mt-3 mb-3">{que.question}</h5>
                        <div className="d-flex justify-content-around">
                          {[
                            {
                              mark: 1,
                              subTitle: t("modal.maintenance.survey.disagree"),
                            },
                            { mark: 2 },
                            { mark: 3 },
                            { mark: 4 },
                            {
                              mark: 5,
                              subTitle: t("modal.maintenance.survey.agree"),
                            },
                          ].map((star, i) => (
                            <span className="text-center text-muted">
                              <i
                                key={i}
                                className={`${
                                  star.mark <= que.answer ? "fas" : "far"
                                } fa-lg fa-fw m-r-10 fa-star fa-2x m-2 hover-pointer`}
                                fontSize="large"
                                onClick={(e) =>
                                  handleAnswer(item, num, star.mark)
                                }
                              />
                              <p>{star.subTitle}</p>
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                ))
              ) : (
                <textarea
                  className="form-control"
                  placeholder="Write a Note..."
                  rows="4"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              )}
            </div>
          </section>
        </ModalBody>
        <ModalFooter>
          <button
            disabled={submitSurveyLoading}
            className="btn btn-primary btn-block m-t-5"
            onClick={(e) => {
              handleSurveySubmit();
            }}
          >
            {!submitSurveyLoading ? (
              `${t("modal.maintenance.submit")}`
            ) : (
              <i className="fas fa-spinner fa-pulse" />
            )}
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-sm-4 col-xl-3 col-12">
          <img src={logo} alt="" />
        </div>
        <div className="col-sm-2 col-xl-2 col-12 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
          <Dropdown
            isOpen={dropDownOpen}
            toggle={toggle}
            className="dropdown navbar-languager"
          >
            <DropdownToggle className="dropdown-toggle" tag="a">
              <span
                className={`flag-icon ${
                  selectedLanguage === "Spanish"
                    ? `flag-icon-es`
                    : `flag-icon-us`
                } m-r-5`}
              ></span>
              <span className="name d-none d-sm-inline">
                {selectedLanguage}
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right">
              <DropdownItem
                onClick={(e) => {
                  setSelectedLanguage("English");
                  i18n.changeLanguage("en");
                }}
              >
                <span className="flag-icon flag-icon-us m-r-5"></span> English
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setSelectedLanguage("Spanish");
                  i18n.changeLanguage("es");
                }}
              >
                <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {!ticketDetails?.fieldData && !ticketDetails?.portalData && (
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <span className="spinner-grow text-primary"></span>
        </div>
      )}

      <button
        className="btn btn-primary float-right mt-2"
        onClick={() => {
          context.handleSetPageSidebar(true);
          context.handleSetPageHeader(true);
          history.push("/dashboard");
        }}
      >
        {isAuth ? "Dashboard" : "Login to Dashboard"}
      </button>

      {ticketDetails && ticketDetails.fieldData ? (
        <div className="card mt-5">
          <h5 className="card-header">Details</h5>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Ticket Number</b>
                </p>
                <p>{ticketDetails.fieldData["zzIDFriendly"] || "-"}</p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Category</b>
                </p>
                <p>
                  {categoryTranslation(ticketDetails.fieldData["Type"] || "-")}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Sub Category</b>
                </p>
                <p>
                  {subCategoryTranslation(
                    ticketDetails.fieldData["SubType"] || "-"
                  )}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Where</b>
                </p>
                <p>
                  {whereListTranslation(
                    ticketDetails.fieldData["SubTypeWhere"] || "-"
                  )}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Status</b>
                </p>
                <p>
                  {statusTranslation(ticketDetails.fieldData["Status"] || "-")}
                </p>
              </div>
              <div className="col-12">
                <p>
                  <b>Description</b>
                </p>
                <p>{ticketDetails.fieldData["Description_Web"] || "-"}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {ticketDetails && ticketDetails.portalData ? (
        <>
          <div className="card mt-5">
            <div className="card-header d-flex justify-content-between">
              <strong>Images</strong>

              {["New", "Scheduled", "Scheduled - Confirmed", "Re-Opened by Tenant"].includes(
                ticketDetails?.fieldData?.Status
              ) ? (
                <>
                  {isAuth ? (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={() => setIsShowImagePicketModal(true)}
                      >
                        Add Image(s)
                      </button>
                      {renderImageDropzoneModal()}
                    </>
                  ) : (
                    <button
                      className="btn btn-success float-right mt-2"
                      onClick={() => gotoLogin()}
                    >
                      Login to add Images
                    </button>
                  )}
                </>
              ) : null}
            </div>
            <div className="card-body">
              <div className="row">
                {ticketDetails.portalData.uploadLogCreate &&
                ticketDetails.portalData.uploadLogCreate.length ? (
                  ticketDetails.portalData.uploadLogCreate.map((x) => {
                    return (
                      <div className="col-6 col-sm-4 col-md-3">
                        <a
                          href={`/gallery?${x["Maintenance_ULOG_create::URL"]}`}
                          target="_blank"
                        >
                          <img
                            src={x["Maintenance_ULOG_create::URL"]}
                            className="img-thumbnail mb-3"
                            style={{
                              height: "100%",
                              width: "100%",
                              maxHeight: 200,
                              objectFit: "contain",
                            }}
                          />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <p>No image added</p>
                )}
              </div>
            </div>
          </div>

          {ticketDetails?.fieldData?.Status !== "Canceled" ? (
            <div className="card mt-5">
              <h5 className="card-header">Notes</h5>
              <div className="card-body">
                <div className="widget-chat widget-chat-rounded">
                  <div
                    className="widget-chat-body"
                    data-scrollbar="true"
                    data-height="235px"
                  >
                    {ticketDetails.portalData.notesCreate &&
                      ticketDetails.portalData.notesCreate.length &&
                      ticketDetails.portalData.notesCreate
                        .filter(
                          (x) =>
                            !x["Maintenance_NOTE_create::ArchiveURL"] &&
                            x["Maintenance_NOTE_create::Note"]
                        )
                        ?.map((item, i) =>
                          !item["Maintenance_NOTE_create::isAddedViaWeb"] ? (
                            <div
                              className="widget-chat-item with-media left"
                              key={i}
                            >
                              <div className="widget-chat-media">
                                <img
                                  alt="alt-img"
                                  src="/assets/img/logo/info.png"
                                />
                              </div>
                              <div className="widget-chat-info">
                                <div className="widget-chat-info-container">
                                  <div className="widget-chat-name text-indigo">
                                    {
                                      item[
                                        "Maintenance_NOTE_create::zzModifiedByWeb"
                                      ]
                                    }
                                  </div>
                                  <div className="widget-chat-message">
                                    {item["Maintenance_NOTE_create::Note"]}
                                  </div>
                                  <div
                                    className="widget-chat-time"
                                    style={{ position: "block" }}
                                  >
                                    {item[
                                          "Maintenance_NOTE_create::zzCreatedTS"
                                        ]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="widget-chat-item right" key={i}>
                              <div className="widget-chat-info">
                                <div className="widget-chat-info-container">
                                  <div className="widget-chat-name text-indigo">
                                    {
                                      item[
                                        "Maintenance_NOTE_create::zzModifiedByWeb"
                                      ]
                                    }
                                  </div>
                                  <div className="widget-chat-message">
                                    {item["Maintenance_NOTE_create::Note_c"] ||
                                      item["Maintenance_NOTE_create::Note"]}
                                  </div>
                                  <div className="widget-chat-time">
                                    {item[
                                          "Maintenance_NOTE_create::zzCreatedTS"
                                        ]}
                                  </div>
                                </div>
                              </div>
                              <div className="widget-chat-media">
                                <img
                                  className="width-full height-full"
                                  alt="alt"
                                  src={
                                    (user && user.Photo_Thumbnail) ||
                                    "/assets/img/user/user.png"
                                  }
                                />
                              </div>
                            </div>
                          )
                        )}
                  </div>
                  {["New", "Scheduled", "Scheduled - Confirmed", "Re-Opened by Tenant"].includes(
                    ticketDetails?.fieldData?.Status
                  ) ? (
                    <>
                      {isAuth ? (
                        <div className="widget-input-container">
                          <div className="widget-input-box">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder={t("modal.maintenance.write-note")}
                              value={note}
                              onChange={(e) =>
                                setNote(e.target.value.toString().slice(0, 100))
                              }
                            />
                          </div>
                          <div className="widget-input-icon">
                            <b>{`${note.length}/100`}</b>
                          </div>
                          <div className="widget-input-icon">
                            {imageLoading ? (
                              <Spinner color="white" size={"sm"} />
                            ) : (
                              <i
                                disabled={imageLoading}
                                className="ion-md-send text-blue-lighter fa-2x hover-pointer"
                                onClick={() => handleAddNote()}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <button
                          className="btn btn-success float-right mt-2"
                          onClick={() => gotoLogin()}
                        >
                          Login to add Note
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      {renderErrorModal()}
      {renderSurveyModal()}
    </div>
  );
};

export default NotSecureMaintainance;
